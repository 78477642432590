.App {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}
.logo {
    width: 200px;
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;
}
.mintContainer {
    position: relative;
    min-height: 350px;
    margin-top: 110px;
    border-radius: 10px;
    background-color: #000000d9;
    max-width: 700px;
    width: 700px;
}
.heading {
    color: #FCE67F;
    font-size: 30px;
    font-weight: bold;
    padding: 40px 0px 5px 15px;
}
.description {
    color: #FDA4D2;
    font-size: 15px;
    font-weight: bold;
    padding: 0px 15px 0px 16px;
}
.minting {
    padding: 20px 20px 10px 20px;
}
.incrimentButton {
    user-select: none;
    width: 40px;
    height: 40px;
    background-color: #97e1fe;
    border-radius: 7px;
    color: #2d2d2d;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    cursor: pointer;
    z-index: 2;
}
.mintNumber {
    width: 50px;
    text-align: center;
    color: #97e1fe;
    font-style: italic;
    font-weight: bold;
}
.mintNow {
    margin: 50px auto 10px auto;
    background-color: #fdc697;
    color: #2d2d2d;
    font-weight: bold;
    display: flex;
    height: 50px;
    width: 90%;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    font-size: 18px;
    cursor: pointer;
    border: 3px solid #000000d9;
}
.totalMinted {
    color: white;
    font-style: italic;
    font-weight: bold;
    position: absolute;
    padding-top: 75px;
}
.nftGif {
    width: 300px;
    border-radius: 30px;
    margin-bottom: 15px;
    pointer-events: none;
}
.flexLayout {
    display: flex;
    align-items: center;
    justify-content: center;
}
.whitelistRemaining {
    color: #43eaad;
    text-align: center;
    font-weight: bold;
    font-style: italic;
    font-size: 15px;
    margin-bottom: 10px;
}
.loader {
    border: 10px solid #fda4d2;
    border-top: 10px solid #83fec0;
    border-bottom: 10px solid #fce67f;
    border-radius: 50%;
    animation: spin 2s linear infinite;
    width: 140px !important;
    height: 140px !important;
}

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}
.isPaused {
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    color: #8bdefc;
}